import {
  ReactElement,
  memo,
  ChangeEvent,
  CSSProperties,
  FocusEvent,
  forwardRef,
} from 'react'
import PropTypes from 'prop-types'
import { VStack, Collapse, Text } from '@chakra-ui/react'

import TextInput from '../../atoms/TextInput'

export interface FormTextInputProps {
  value: string
  placeholder?: string
  name?: string
  type?: 'email' | 'password' | 'search' | 'tel' | 'text' | 'url'
  inputMode?:
    | 'email'
    | 'decimal'
    | 'numeric'
    | 'search'
    | 'tel'
    | 'text'
    | 'url'
    | 'none'
  autoComplete?: string
  autoFocus?: boolean
  isRequired?: boolean
  isDisabled?: boolean
  isReadOnly?: boolean
  isInvalid?: boolean
  isOk?: boolean
  errorText?: string
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  className?: string
  id?: string
  style?: CSSProperties
}

const FormTextInput = forwardRef<HTMLInputElement, FormTextInputProps>(
  (
    {
      id,
      className,
      style,
      onBlur,
      onChange,
      onFocus,
      value,
      errorText,
      isRequired,
      isDisabled,
      isReadOnly,
      isInvalid,
      isOk,
      type,
      inputMode,
      autoComplete,
      autoFocus,
      name,
      placeholder,
    }: FormTextInputProps,
    ref,
  ): ReactElement => {
    return (
      <VStack
        id={id}
        className={className}
        style={style}
        spacing="0"
        sx={{
          alignItems: 'stretch',
        }}
      >
        <TextInput
          placeholder={placeholder}
          type={type}
          name={name}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          isInvalid={isInvalid}
          isRequired={isRequired}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          isOk={isOk}
          inputMode={inputMode}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          ref={ref}
          sx={{
            borderRadius: 0,
          }}
          size="lg"
        />
        <Collapse in={isInvalid} animateOpacity>
          <Text textStyle="auth.errorText">* {errorText}</Text>
        </Collapse>
      </VStack>
    )
  },
)

FormTextInput.propTypes = {
  value: PropTypes.string.isRequired,
}

FormTextInput.defaultProps = {
  type: 'text',
  className: '',
  isInvalid: false,
  isOk: false,
  isRequired: false,
  isDisabled: false,
  isReadOnly: false,
  autoFocus: false,
}

export default memo(FormTextInput)
