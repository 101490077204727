import { ReactElement, memo, ReactNode } from 'react'
import { VStack, Divider, Icon, Flex, useTheme } from '@chakra-ui/react'
import { Global, css } from '@emotion/react'

import TermAndPolicyLinks from '@/features/auth/components/atoms/TermAndPolicyLinks'

import { ReactComponent as Logo } from '@/svg/logo.svg'

interface Props {
  className?: string
  id?: string
  children: ReactNode
}

function SetPasswordForm({ id, className, children }: Props): ReactElement {
  const theme = useTheme()
  return (
    <Flex
      id={id}
      className={className}
      spacing="0px"
      layerStyle="auth.container"
      direction="column"
      sx={{
        bg: 'white',
        my: '20',
      }}
    >
      <Global
        styles={css`
          body {
            background-color: ${theme.colors.primary[400]};
          }
        `}
      />
      <Icon
        as={Logo}
        sx={{
          alignSelf: 'center',
          width: '190px',
          maxWidth: '94%',
          h: 'auto',
          mt: '7',
          mb: '7',
        }}
      />
      <Divider />
      {children}
      <VStack layerStyle="auth.termAndPolicyContainer" alignSelf="stretch">
        <TermAndPolicyLinks />
      </VStack>
    </Flex>
  )
}

SetPasswordForm.propTypes = {}

SetPasswordForm.defaultProps = {
  className: '',
}

export default memo(SetPasswordForm)
