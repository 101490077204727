import { useContext } from 'react'
import { useFormik, FormikProps, FormikHelpers } from 'formik'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useMutation } from 'react-query'

import { signInSchema } from '@/features/auth/schema'
import authApi from '@/features/auth/api/v1'

import AccountContext from '@/contexts/account'

import { dashBoardRoutes, passwordExpirationRoute } from '@/routes'

interface Form {
  email: string
  password: string
  isRemember: boolean
}

interface UseFormResult {
  isLoading: boolean
  isError: boolean
  error: AxiosError
  formik: FormikProps<Form>
}

export function useForm(): UseFormResult {
  const router = useRouter()

  const accountContext = useContext(AccountContext)

  const { isLoading, mutate, isError, error } = useMutation(authApi.signIn)

  const formik = useFormik<Form>({
    initialValues: {
      email: '',
      password: '',
      isRemember: false,
    },
    validateOnChange: false,
    validationSchema: signInSchema,
    onSubmit: (
      values: Form,
      { resetForm, validateForm }: FormikHelpers<Form>,
    ) => {
      validateForm(values).then(() => {
        mutate(
          {
            data: {
              email: values.email,
              password: values.password,
            },
            config: {},
            options: {
              persist: values.isRemember,
            },
          },
          {
            onSuccess: (res) => {
              resetForm()
              accountContext.setAccount(res.data.user)

              if (res.data.user?.need_change_password) {
                router.replace(passwordExpirationRoute.href)
              } else {
                router.replace(dashBoardRoutes.href)
              }
            },
          },
        )
      })
    },
  })

  return {
    isLoading,
    error: error as AxiosError,
    isError,
    formik,
  }
}
