import { memo, ReactElement } from 'react'
import { VStack } from '@chakra-ui/react'

import SignInForm from '@/features/auth/templates/SignIn/Form'
import PageContainer from '@/features/auth/components/organisms/PageContainer'

function SignInPage(): ReactElement {
  return (
    <VStack layerStyle="auth.page">
      <PageContainer>
        <SignInForm />
      </PageContainer>
    </VStack>
  )
}

SignInPage.propTypes = {}

export default memo(SignInPage)
