import { useState, useCallback } from 'react'
import {
  useTransition,
  UseTransitionResult,
  useSpring,
  AnimatedValue,
} from 'react-spring'
import { useTheme } from '@chakra-ui/react'
import { DEFAULT_SPRING_CONFIG } from '@/common/constants/spring'

interface SpringValue {
  percentage: number
  color: string
  transform: string
}

interface UsePlaceholderAnimationResult {
  placeholderSpring: AnimatedValue<SpringValue>
  handleFocus: () => void
  handleBlur: () => void
  isFocused: boolean
  theme: Dict
}

export function usePlaceholderAnimation(
  value: string,
  colorScheme = 'primary',
  isInvalid = false,
): UsePlaceholderAnimationResult {
  const theme = useTheme()

  const [isFocused, setFocused] = useState(false)

  const handleFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const handleBlur = useCallback(() => {
    setFocused(false)
  }, [])

  let placeholderColor = theme.colors.gray[200]

  if (isInvalid) {
    placeholderColor = theme.colors.status.error.color
  } else if (isFocused) {
    placeholderColor = theme.colors[colorScheme][400]
  }

  const placeholderSpring = useSpring({
    to: {
      percentage: isFocused || value ? 1 : 0,
      color: placeholderColor,
      transform:
        isFocused || value
          ? 'scale(0.8) translate3d(-10%, -62.5%, 0)'
          : 'scale(1) translate3d(0%, 0%, 0)',
    },
    config: DEFAULT_SPRING_CONFIG,
  }) as AnimatedValue<SpringValue>

  return {
    placeholderSpring,
    handleFocus,
    handleBlur,
    isFocused,
    theme,
  }
}

interface EyesTransitionValue {
  opacity: number
  transform: string
}

interface UseEyeAnimationResult {
  handleTogglePassword: () => void
  isShowPassword: boolean
  eyeTransitions: UseTransitionResult<boolean, EyesTransitionValue>[]
}

export function useEyeAnimation(): UseEyeAnimationResult {
  const [isShowPassword, setShowPassword] = useState(false)

  const handleTogglePassword = useCallback(() => {
    setShowPassword(!isShowPassword)
  }, [isShowPassword])

  const eyeTransitions = useTransition(isShowPassword, null, {
    from: { opacity: 0, transform: 'translate3d(0, -40%, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0%, 0)' },
    leave: { opacity: 0, transform: 'translate3d(0, 40%, 0)' },
    config: DEFAULT_SPRING_CONFIG,
  }) as UseTransitionResult<boolean, EyesTransitionValue>[]

  return {
    isShowPassword,
    handleTogglePassword,
    eyeTransitions,
  }
}
