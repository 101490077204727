import { string, object, ref } from 'yup'
import { errors } from '@/common/constants/text'

const passwordValidationRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!$%@^&*#()_+|~=`[\]:\\";'<>?,./]).*$/

export const passwordSchema = string()
  .min(8, errors.passwordTooShort)
  .required(errors.required)
  .matches(passwordValidationRegex, errors.passwordInvalid)

export const signInSchema = object().shape({
  email: string().required(errors.required).email(errors.emailInvalid),
  password: string().required(errors.required),
})

export const forgotPasswordSchema = object().shape({
  email: string().required(errors.required).email(errors.emailInvalid),
})

export const setPasswordSchema = object().shape({
  reset_password_token: string().required(),
  password: passwordSchema,
  password_confirmation: string().oneOf(
    [ref('password'), null],
    errors.passwordDoNotMatch,
  ),
})

export const signUpSchema = object().shape({
  email: string().required(errors.required).email(errors.emailInvalid),
  password: passwordSchema,
  password_confirmation: string().oneOf(
    [ref('password'), null],
    errors.passwordDoNotMatch,
  ),
  company_name: string().required(errors.required),
  phone_number: string().required(errors.required),
})

export const changePasswordSchema = object().shape({
  email: string().required(errors.required).email(errors.emailInvalid),
  current_password: string().required(errors.required),
  password: passwordSchema,
  password_confirmation: string().oneOf(
    [ref('password'), null],
    errors.passwordDoNotMatch,
  ),
})

export const passwordExpirationSchema = object().shape({
  current_password: string().required(errors.required),
  password: passwordSchema,
  password_confirmation: string().oneOf(
    [ref('password'), null],
    errors.passwordDoNotMatch,
  ),
})

export const verifyPasswordSchema = object().shape({
  password: string().required(errors.required),
})
export const changeEmailSchema = object().shape({
  email: string().required(errors.required),
})
