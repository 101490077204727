import { CSSProperties, memo, ChangeEvent, FocusEvent, forwardRef } from 'react'
import {
  Input,
  Box,
  Icon,
  InputGroup,
  InputRightElement,
  Button,
  Text,
  CSSObject,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { FiEye, FiEyeOff } from 'react-icons/fi'

import { AnimatedBox } from '@/lib/springComponent'

import { usePlaceholderAnimation, useEyeAnimation } from './methods'

interface Props {
  value: string
  placeholder?: string
  name?: string
  type?: 'email' | 'password' | 'search' | 'tel' | 'text' | 'url'
  inputMode?:
    | 'email'
    | 'decimal'
    | 'numeric'
    | 'search'
    | 'tel'
    | 'text'
    | 'url'
    | 'none'
  autoComplete?: string
  autoFocus?: boolean
  isRequired?: boolean
  isDisabled?: boolean
  isReadOnly?: boolean
  isInvalid?: boolean
  isOk?: boolean
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void
  size?: string
  colorScheme?: string
  variant?: 'filled' | 'outline' | 'ghost' | 'flushed'
  className?: string
  id?: string
  style?: CSSProperties
  sx?: CSSObject
}

const TextInput = forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const {
    id,
    className,
    style,
    onBlur,
    onChange,
    onFocus,
    value,
    isRequired,
    isDisabled,
    isReadOnly,
    isInvalid,
    isOk,
    type,
    inputMode,
    autoComplete,
    autoFocus,
    name,
    placeholder,
    size,
    colorScheme,
    variant,
    sx,
  } = props

  const {
    eyeTransitions,
    isShowPassword,
    handleTogglePassword,
  } = useEyeAnimation()

  const {
    placeholderSpring,
    handleFocus,
    handleBlur,
    theme,
  } = usePlaceholderAnimation(value, colorScheme, isInvalid)

  const height = theme.components.Input.sizes[size as string]?.field.h || 10
  const isShowCustomPlaceholder = !(variant === 'filled' || variant === 'ghost')

  return (
    <Box
      id={id}
      className={className}
      style={style}
      sx={{
        h: height,
        position: 'relative',
        borderRadius: 'base',
      }}
    >
      {isShowCustomPlaceholder && (
        <AnimatedBox
          style={{
            transform: placeholderSpring.transform,
            color: placeholderSpring.color,
            zIndex: placeholderSpring.percentage.interpolate((value: number) =>
              value > 0.005 ? 2 : 0,
            ),
            opacity: 1,
          }}
          sx={{
            position: 'absolute',
            top: '0',
            left: '0',
            ml: variant !== 'flushed' ? 3 : 0,
            h: height,
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <Text
            sx={{
              bg: 'white',
              borderRadius: 'base',
              px: '1',
            }}
          >
            {placeholder}
          </Text>
        </AnimatedBox>
      )}
      <InputGroup
        sx={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          h: 'fit-content',
        }}
      >
        <Input
          placeholder={!isShowCustomPlaceholder ? placeholder : ''}
          type={type !== 'password' || !isShowPassword ? type : 'text'}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={(e) => {
            handleFocus()
            onFocus?.(e)
          }}
          onBlur={(e) => {
            handleBlur()
            onBlur?.(e)
          }}
          isInvalid={isInvalid}
          isRequired={isRequired}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          inputMode={inputMode}
          autoFocus={autoFocus}
          autoComplete={autoComplete}
          colorScheme={colorScheme}
          variant={variant}
          size={size}
          sx={{
            borderColor: isOk ? 'status.ok.color' : 'gray.400',
            pr: type === 'password' ? 16 : 6,
            ...sx,
          }}
          ref={ref}
        />
        {type === 'password' && (
          <InputRightElement layerStyle="inputRightElement">
            <Button
              onClick={handleTogglePassword}
              variant="ghost"
              colorScheme="gray"
              sx={{
                borderRadius: 'md',
                height: '80%',
                width: Math.min(height - 4, 10),
              }}
            >
              {eyeTransitions.map(
                ({
                  item,
                  key,
                  props,
                }: {
                  item: boolean
                  key: string
                  props: CSSProperties
                }) =>
                  item ? (
                    <AnimatedBox key={key} style={props} position="absolute">
                      <Icon
                        as={FiEye}
                        sx={{
                          boxSize: Math.min(height - 4, 6),
                          color: 'gray.200',
                        }}
                      />
                    </AnimatedBox>
                  ) : (
                    <AnimatedBox key={key} style={props} position="absolute">
                      <Icon
                        as={FiEyeOff}
                        sx={{
                          boxSize: Math.min(height - 4, 6),
                          color: 'gray.200',
                        }}
                      />
                    </AnimatedBox>
                  ),
              )}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>
    </Box>
  )
})

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
}

TextInput.defaultProps = {
  className: '',
  variant: 'outline',
  size: 'md',
}

export default memo(TextInput)
