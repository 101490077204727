import { ReactElement, CSSProperties, memo } from 'react'
import { Text, Link } from '@chakra-ui/react'

import { termsRoute, policyRoute } from '@/routes/external'

interface Props {
  className?: string
  id?: string
  style?: CSSProperties
}

function TermAndPolicyLinks({ id, className, style }: Props): ReactElement {
  return (
    <Text
      id={id}
      className={className}
      style={style}
      align="center"
      sx={{
        fontSize: 'xs',
        fontWeight: 'medium',
      }}
    >
      <Link
        href={termsRoute.href}
        isExternal
        sx={{
          color: 'secondary.400',
        }}
      >
        {termsRoute.title}
      </Link>{' '}
      และ{' '}
      <Link
        href={policyRoute.href}
        isExternal
        sx={{
          color: 'secondary.400',
        }}
      >
        {policyRoute.title}
      </Link>
    </Text>
  )
}

TermAndPolicyLinks.propTypes = {}

TermAndPolicyLinks.defaultProps = {
  className: '',
}

export default memo(TermAndPolicyLinks)
