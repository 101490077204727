import { GetStaticPropsContext, GetStaticPropsResult } from 'next'

import Page from '@/features/auth/pages/SignIn'

export async function getStaticProps(
  _: GetStaticPropsContext,
): Promise<GetStaticPropsResult<{}>> {
  return {
    props: {},
  }
}

export default Page
