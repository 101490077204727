import { ReactElement, memo } from 'react'
import NextLink from 'next/link'
import {
  Box,
  VStack,
  HStack,
  Button,
  Checkbox,
  Link,
  Text,
  Collapse,
} from '@chakra-ui/react'
import BeatLoader from 'react-spinners/BeatLoader'

import FormTextInput from '@/common/components/molecules/FormTextInput'

import { getErrorStrings } from '@/lib/utils/formatter/errors'

import {
  forgotPasswordRoute,
  resendConfirmAccountRoute,
  signUpRoute,
} from '@/routes'

import { useForm } from './methods'

interface Props {
  className?: string
  id?: string
}

function SignInForm({ id, className }: Props): ReactElement {
  const { isLoading, error, formik, isError } = useForm()

  return (
    <Box
      id={id}
      className={className}
      py="10"
      px="4"
      width="100%"
      layerStyle="auth.container"
    >
      <VStack spacing="6" alignItems="stretch" layerStyle="auth.content">
        <VStack spacing="2" alignItems="flex-start">
          <Text as="h1" textStyle="auth.titleText">
            เข้าสู่ระบบ
          </Text>
          <Text as="h1" textStyle="auth.subtitleText">
            หากยังไม่มีบัญชี{' '}
            <NextLink href={signUpRoute.href} passHref>
              <Link>สมัครสมาชิก</Link>
            </NextLink>
          </Text>
          <Collapse in={isError} animateOpacity>
            {getErrorStrings(error).map((text: string) => (
              <Text textStyle="auth.errorText" key={text}>
                * {text}
              </Text>
            ))}
          </Collapse>
        </VStack>
        <form onSubmit={formik.handleSubmit}>
          <VStack
            spacing="6"
            justifyContent="flex-start"
            alignItems="stretch"
            width="100%"
            p="0"
          >
            <FormTextInput
              autoFocus
              autoComplete="email"
              isInvalid={Boolean(formik.errors.email)}
              errorText={formik.errors.email}
              placeholder="อีเมลของคุณ"
              type="text"
              inputMode="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
            />
            <FormTextInput
              isInvalid={Boolean(formik.errors.password)}
              errorText={formik.errors.password}
              placeholder="รหัสผ่าน"
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
            />
            <HStack justifyContent="space-between">
              <Checkbox
                name="isRemember"
                isChecked={formik.values.isRemember}
                onChange={formik.handleChange}
                size="sm"
              >
                จดจำรหัสผ่าน
              </Checkbox>
            </HStack>
            <Button
              type="submit"
              isLoading={isLoading}
              spinner={<BeatLoader size={8} color="currentColor" />}
              h="16"
            >
              เข้าสู่ระบบ
            </Button>
            <VStack>
              <Text textStyle="auth.subtitleText">
                จำรหัสผ่านไม่ได้?{' '}
                <NextLink href={forgotPasswordRoute.href} passHref>
                  <Link>ขอรหัสผ่านใหม่ที่นี่</Link>
                </NextLink>
              </Text>
              <Text textStyle="auth.subtitleText">
                ไม่ได้รับอีเมลสำหรับยืนยันตัวตน?{' '}
                <NextLink href={resendConfirmAccountRoute.href} passHref>
                  <Link>ขออีเมลอีกครั้งที่นี่</Link>
                </NextLink>
              </Text>
            </VStack>
          </VStack>
        </form>
      </VStack>
    </Box>
  )
}

SignInForm.propTypes = {}

SignInForm.defaultProps = {
  className: '',
}

export default memo(SignInForm)
